// Group links by category
export const sidebarCategories = {
	Personal: [
		{ title: 'Home', href: '/' },
		{ title: 'CV', href: '/cv' },
	],
	Gists: [
		{ title: 'GitHub API Route', href: '/gist/github-api-route' },
		{ title: 'GitHub Profile API', href: '/gist/github-profile-api' },
		{ title: 'Theme Switcher', href: '/gist/theme-switcher-component' },
		{ title: 'Scroll To Top', href: '/gist/scroll-to-top-component' },
		{ title: 'Date Utility', href: '/gist/date-utility' },
		{ title: 'Format Date Utility', href: '/gist/format-date-utility' },
		{ title: 'Text Cleaner', href: '/gist/text-cleaner' },
		{ title: 'Site URL Utility', href: '/gist/site-url-utility' },
		{ title: 'Copy Directory Utility', href: '/gist/copy-directory-utility' },
		{ title: 'Delete Folder', href: '/gist/delete-folder' },
		{ title: 'Random Color Generator', href: '/gist/random-color-generator' },
		{ title: 'Regex Text Utility', href: '/gist/regex-text-utility' },
		{
			title: 'Convert Time to Milliseconds',
			href: '/gist/convert-time-to-milliseconds',
		},
		{ title: 'Markdown File Renamer', href: '/gist/markdown-file-renamer' },
		{ title: 'Profile Age Calculator', href: '/gist/profile-age-calculator' },
		{ title: 'GitHub Data Store', href: '/gist/github-data-store' },
		{ title: 'GitHub Repo Filter', href: '/gist/github-repo-filter' },
		{ title: 'GitHub Repo Model', href: '/gist/github-repo-model' },
		{ title: 'GitHub Repo Stats', href: '/gist/github-repo-stats' },
		{ title: 'GitHub User Model', href: '/gist/github-user-model' },
		{ title: 'Git Tag Push Utility', href: '/gist/git-tag-push-utility' },
		{ title: 'Next.js Manifest Config', href: '/gist/nextjs-manifest-config' },
		{ title: 'Website Configuration', href: '/gist/website-configuration' },
		{ title: 'Tailwind Page Setup', href: '/gist/tailwind-page-setup' },
		{
			title: 'Service Configuration Guide',
			href: '/gist/service-configuration-guide',
		},
		{ title: 'Dotnet API Setup', href: '/gist/dotnet-api-setup' },
		{
			title: 'Redux Root Reducer Setup',
			href: '/gist/redux-root-reducer-setup',
		},
		{
			title: 'Dictionary Context Provider',
			href: '/gist/dictionary-context-provider',
		},
		{ title: 'Article Heading Styles', href: '/gist/article-heading-styles' },
		{
			title: 'Electron Window Manager',
			href: '/gist/electron-window-manager-utility',
		},
		{ title: 'Audio Lyrics Writer', href: '/gist/audio-lyrics-writer' },
		{ title: 'Auth Verifier', href: '/gist/auth-verifier' },
		{ title: 'Combine Locales', href: '/gist/combine-locales' },
		{ title: 'Contact Form Handler', href: '/gist/contact-form-handler' },
		{ title: 'Date Range Slicer', href: '/gist/date-range-slicer' },
		{ title: 'Fetch Hook', href: '/gist/fetch-hook' },
		{
			title: 'Hello World Translations',
			href: '/gist/hello-world-translations',
		},
		{
			title: 'TypeScript Glob Patterns',
			href: '/gist/typescript-glob-patterns',
		},
	],
}
